import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { UserService } from './service/user.service';
import { ChatService } from '@soctrip-common/chat';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_SEVERITY } from './shared/enum/toast-severity.enum';
import { BREAKPOINT } from './shared/enum/breakpoint-enum';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'socchat-app';
  baseUrl = environment.baseURL;
  userInfo: any;
  isDetailView: boolean;
  module: string;
  isEnableChatBot = false;
  isLoading = true;
  isShowOverlayBtnMenu = false;
  options: any[] = [];
  env = environment;
  screenWidth: number;
  TOAST_SEVERITY = TOAST_SEVERITY;
  BREAKPOINT = BREAKPOINT;

  constructor(
    private userService: UserService,
    private chatService: ChatService,
    private translateService: TranslateService,
  ) {
    Object.keys(localStorage).forEach(key => {
      localStorage.removeItem(key);
    });
    // this.isLoading = false;
    // this.module = 'TRAVEL';
    // this.isEnableChatBot = true;
    // this.userInfo = JSON.parse(localStorage.getItem('user_profile')!);
    this.options = [
      {
        id: 'DELETE',
        label: 'CHAT.DELETE_CHAT'
      }
    ];
    window.addEventListener('message', event => {
      if (event) {
        const message = event.data;
        switch (message.type) {
          case 'initial':
            localStorage.setItem('access_token', JSON.stringify({ accessToken: message.data.token }));
            this.userService.getUserInfo(message.data.userId).subscribe((res) => {
              localStorage.setItem('user_profile', JSON.stringify(res.data));
              this.userInfo = res.data;
              this.translateService.setDefaultLang(res.data?.language?.toLowerCase() || 'en');
              this.isLoading = false;
            });
            this.isEnableChatBot = message.data.isEnableChatBot || false;
            this.module = message.data.module;
            if (message.data.agencyId) {
              const contactInfo = {
                agencyId: message.data.agencyId,
                meta_data: message.data.meta_data,
              };
              this.chatService.chatAgencySupportSubject.next(contactInfo);
            };
            break;
          default:
            break;
        }
      }
    });
  }

  goBack() {
    if (this.isDetailView) {
      this.isDetailView = false;
      return;
    }
    window.postMessage('goBack', '*');
  }

  handleSelectAction() {
    this.isShowOverlayBtnMenu = false;
    this.chatService.deleteChatSubject.next(true);
  }
}
