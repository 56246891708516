<div class="border border-palette-gray-200 h-12 w-full flex items-center justify-between px-4">
  <i id="chat-back-btn" class="sctr-icon-chevron-left text-xl" (click)="goBack()"></i>
  <div class="font-semibold text-base">{{'CHAT.CHAT' | translate}}</div>
  <i *ngIf="isDetailView; else searchTemplate" 
    class="sctr-icon-dots-vertical text-xl text-palette-gray-900 hover:bg-palette-gray-50"
    (click)="isShowOverlayBtnMenu = true"
  ></i>
</div>
<p-overlay *ngIf="isShowOverlayBtnMenu" [(visible)]="isShowOverlayBtnMenu"
  [contentStyleClass]="'px-4 shadow-2'" [styleClass]="'z-10 float-left absolute !left-auto !right-[0px]'">
  <p-listbox [options]="options" class="p-0 flex justify-end" [styleClass]="'w-max py-1 rounded-lg border-[#EAECF0] border sc-action-menu'">
    <ng-template let-item pTemplate="item">
      <button
        class="flex justify-start px-5 py-3 gap-2 w-full disabled:opacity-50 disabled:!pointer-events-none disabled:!cursor-default" 
        (click)="handleSelectAction()"
      >
        <div class="text-sm">{{ item.label | translate }}</div>
      </button>
    </ng-template>
  </p-listbox>
</p-overlay>
<lib-chat-popup *ngIf="!isLoading; else loading_data" [isMobileWebView]="true" [userInfo]="userInfo" [baseUrl]="baseUrl"
  [(isDetailView)]="isDetailView" [module]="module" [isEnableChatBot]="isEnableChatBot"
  [isDisableCloseDialogFromOutside]="true" [domain]="env.CHAT_DOMAIN"></lib-chat-popup>

<ng-template #loading_data>
  <div class="flex flex-col p-4" *ngFor="let _ of [].constructor(3)">
    <div class="flex">
      <div class="mr-2">
        <p-skeleton size="2rem" shape="circle"></p-skeleton>
      </div>
      <div class="flex items-center">
        <div class="flex flex-col">
          <p-skeleton width="8rem" height="1rem" class="mb-1"></p-skeleton>
          <p-skeleton width="4rem" height="0.75rem"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #searchTemplate>
  <i class="sctr-icon-search-md text-xl text-palette-base-white hover:bg-palette-gray-50"></i>
</ng-template>
<p-confirmDialog></p-confirmDialog>
<p-toast
  #toast
  [position]="screenWidth >= 768 ? 'top-right' : 'bottom-center'"
  [baseZIndex]="9999"
  [breakpoints]="{
    '768px': { width: '90%', bottom: '4rem' }
  }"
>
  <ng-template let-message pTemplate="message">
    <div
      class="flex items-center self-stretch w-full gap-3 py-4 pl-4 pr-3"
      [ngClass]="{
        'cursor-pointer': message.data?.clickCb,
        'text-palette-gray-700': message.severity === TOAST_SEVERITY.DEFAULT,
        'text-branding-primary-600': message.severity === TOAST_SEVERITY.PRIMARY,
        'text-system-error-600': message.severity === TOAST_SEVERITY.ERROR,
        'text-system-warning-600': message.severity === TOAST_SEVERITY.WARN,
        'text-system-success-600': message.severity === TOAST_SEVERITY.SUCCESS,
        'text-palette-cyan-600': message.severity === TOAST_SEVERITY.INFO
      }"
    >
      <i
        class="block text-2xl/none self-baseline"
        [ngClass]="
          message.icon
            ? message.icon
            : {
                'sctr-icon-info-circle':
                  message.severity === TOAST_SEVERITY.DEFAULT ||
                  message.severity === TOAST_SEVERITY.PRIMARY ||
                  message.severity === TOAST_SEVERITY.INFO,
                'sctr-icon-alert-circle': message.severity === TOAST_SEVERITY.ERROR,
                'sctr-icon-check-circle': message.severity === TOAST_SEVERITY.SUCCESS,
                'sctr-icon-alert-triangle': message.severity === TOAST_SEVERITY.WARN
              }
        "
      ></i>
      <div class="flex flex-col items-start w-full gap-1 text-md">
        <p *ngIf="message.summary" class="font-semibold" [innerHTML]="message.summary"></p>
        <p
          *ngIf="message.detail"
          class="font-medium whitespace-pre-line"
          [innerHTML]="message.detail"
        ></p>
      </div>
    </div>
  </ng-template>
</p-toast>